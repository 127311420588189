/* eslint-disable no-console */
import { sleep } from '@chainflip/utils/async';
import { isFullfilled, isRejected, isTruthy } from '@chainflip/utils/guard';
import { type WalletContextState } from '@solana/wallet-adapter-react';
import { type Connection } from '@solana/web3.js';
import { ethers } from 'ethers';
import { type ChainId, type ChainData } from '@/shared/assets/chains';
import { type Token } from '@/shared/assets/tokens';
import { type WalletClient } from '@/shared/hooks/useEthersSigner';
import { entries } from '@/shared/utils';
import { type ChainflipIntegration } from './chainflip';
import { type SquidIntegration } from './squid';
import { storeRouteInLocalStorage } from './storage';
import {
  type Integration,
  type RouteRequest,
  type RouteResponse,
  type StatusResponse,
  type ExecuteSwapResponse,
  type PrepareSwapResponse,
} from './index';

export interface BaseIntegration<T extends Token = Token> {
  readonly name: string;
  readonly logo: (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;
  getChains(): Promise<ChainData[]>;
  getTokens(chainId: ChainId): Promise<T[]>;
  getDestinationChains(srcChainId: ChainId): Promise<ChainData[]>;
  getRoutes(routeParams: RouteRequest): Promise<RouteResponse[]>;
  getStatus(swapId: string): Promise<StatusResponse | undefined>;
  executeEvmSwap(swapId: string, walletClient: WalletClient): Promise<ExecuteSwapResponse>;
  executeSolanaSwap(
    swapId: string,
    wallet: WalletContextState,
    connection: Connection,
  ): Promise<ExecuteSwapResponse>;
}

export const getDeterministicRouteId = (route: Omit<RouteResponse, 'id'>) => {
  const identificationData = [
    route.srcAmount.toString(),
    route.integration,
    route.steps.map((step) => [
      step.protocolName,
      step.srcToken.chain.id,
      step.srcToken.address,
      step.destToken.chain.id,
      step.destToken.address,
    ]),
    route.durationSeconds,
  ];

  return ethers.keccak256(ethers.toUtf8Bytes(JSON.stringify(identificationData)));
};

export class IntegrationManager {
  static from(integrations: Record<Integration, () => BaseIntegration | Promise<BaseIntegration>>) {
    let integrationTuples = entries(integrations);

    if (isTruthy(process.env.NEXT_PUBLIC_INTEGRATION_WHITELIST)) {
      const whitelist = process.env.NEXT_PUBLIC_INTEGRATION_WHITELIST.split(',');
      integrationTuples = integrationTuples.filter(([integration]) =>
        whitelist.includes(integration),
      );
    }

    const awaitedIntegrations = {} as Record<Integration, BaseIntegration>;
    const instance = new IntegrationManager(awaitedIntegrations);

    Promise.all(
      integrationTuples.map(async ([name, getManager]) => {
        try {
          const controller = new AbortController();
          const awaited = await Promise.race([
            getManager(),
            sleep(10000, { signal: controller.signal }).then(() =>
              Promise.reject(Error('timeout')),
            ),
          ]);
          controller.abort();
          if (awaited) awaitedIntegrations[name] = awaited;
        } catch (error) {
          console.error('failed to initialize integration', name, error);
        }
      }),
    ).then(() => {
      instance.ready = true;
    });

    return instance;
  }

  private ready = false;

  private constructor(private readonly integrations: Record<Integration, BaseIntegration>) {
    this.integrations = integrations;
  }

  private async ensureReady() {
    while (!this.ready) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(10);
    }
  }

  getIntegration(integration: 'chainflip'): ChainflipIntegration;
  getIntegration(integration: 'squid'): SquidIntegration;
  getIntegration(integration: Integration): BaseIntegration;
  getIntegration(integration: string): BaseIntegration {
    if (!(integration in this.integrations)) {
      throw new Error(`integration "${integration}" not found`);
    }

    return this.integrations[integration as Integration];
  }

  getName = (integration: Integration) => this.getIntegration(integration).name;

  getLogo = (integration: Integration) => this.getIntegration(integration).logo;

  getRoutes = async (routeRequest: RouteRequest): Promise<RouteResponse[]> => {
    await this.ensureReady();
    const promises = Object.values(this.integrations).map((i) => i.getRoutes(routeRequest));

    const results = await Promise.allSettled(promises);
    const fulfilled = results.filter(isFullfilled).flatMap((result) => result.value);

    for (const result of results.filter(isRejected)) {
      console.error('error getting all routes:', result.reason);
    }
    return fulfilled;
  };

  getStatus = async (
    integration: Integration,
    swapId: string,
  ): Promise<StatusResponse | undefined> => {
    await this.ensureReady();
    return this.getIntegration(integration).getStatus(swapId);
  };

  prepareSwap = async (
    route: RouteResponse,
    destAddress: string,
    refundAddress: string | undefined,
  ): Promise<PrepareSwapResponse> => {
    await this.ensureReady();

    let preparedSwapId;
    try {
      preparedSwapId = crypto.randomUUID();
    } catch {
      // crypto is only available in https environments
      preparedSwapId = Math.random().toString();
    }
    storeRouteInLocalStorage(route.integration, preparedSwapId, {
      ...route,
      destAddress,
      refundAddress,
    });

    return {
      integration: route.integration,
      id: preparedSwapId,
    };
  };

  executeEvmSwap = async (
    integration: Integration,
    swapId: string,
    walletClient: WalletClient,
  ): Promise<ExecuteSwapResponse> => {
    await this.ensureReady();
    return this.getIntegration(integration).executeEvmSwap(swapId, walletClient);
  };
}
